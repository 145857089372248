/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
   aws_project_region: "eu-central-1",
   aws_cognito_identity_pool_id: "eu-central-1:6bb79962-8987-46b9-85d6-5b3813298738",
   aws_cognito_region: "eu-central-1",
   aws_user_pools_id: "eu-central-1_RnROkoOOE",
   aws_user_pools_web_client_id: "4d5fgfmfi3eujfvihghkimtchn",
   oauth: {},
   aws_cognito_username_attributes: [],
   aws_cognito_social_providers: [],
   aws_cognito_signup_attributes: [],
   aws_cognito_mfa_configuration: "OFF",
   aws_cognito_mfa_types: [],
   aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 6,
      passwordPolicyCharacters: [],
   },
   aws_cognito_verification_mechanisms: ["EMAIL"],
   aws_appsync_graphqlEndpoint: "https://sqhw6nfrfzbdpphak6q4mtmcey.appsync-api.eu-central-1.amazonaws.com/graphql",
   aws_appsync_region: "eu-central-1",
   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
   geo: {
      amazon_location_service: {
         region: "eu-central-1",
         maps: {
            items: {
               "routingMap-master": {
                  style: "VectorEsriStreets",
               },
               "routingMapNav-master": {
                  style: "VectorEsriNavigation",
               },
            },
            default: "routingMapNav-master",
         },
         search_indices: {
            items: ["routingIndex-master"],
            default: "routingIndex-master",
         },
      },
   },
}

export default awsmobile
