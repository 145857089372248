import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
   palette: {
      primary: { main: "#00509b", light: "#4f7ccc", dark: "#00296c" },
      secondary: { main: "#0088CC", light: "#5cb8ff", dark: "#005b9b" },
   },
   typography: {
      fontFamily: [
         '"Exo 2"',
         "Roboto",
         "-apple-system",
         "BlinkMacSystemFont",
         '"Segoe UI"',
         '"Helvetica Neue"',
         "Arial",
         "sans-serif",
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
      ].join(","),
   },
   components: {
      MuiCssBaseline: {
         styleOverrides: {
            display: "flex",
            minHeight: "100dvh",
            flexDirection: "column",
         },
      },
   },
})
